import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Home from './Home';
import './Nav.css';
import Button from '@material-ui/core/Button';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';




const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
 
  bigAvatar: {
    margin: 10,
    width: 150,
    height: 150,
  },
  rotate:{
    transform: `rotate(${270}deg)` 
  },
  backColor:{
    background: 'black',
 
  }
  
}));

export default function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  function HomeIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }
  
  function handleClick() {
    console.log("secondList clicked")
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  
  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const drawer = (
    <div >
      {/* INSERT IN DIV BELOW IF NEEDED className={classes.toolbar} */}
      
      <Grid container justify="center" alignItems="center" className={classes.rotate}>
      <Avatar src={require('./pics/mel.JPG')} data-aos="fade-in" className ={classes.bigAvatar}/>
      </Grid>
      <h1 className="oxygen">Melvin Tham</h1>
      <Divider />
      <List>

      <Link activeClass="active" to="1" spy={true} smooth={true} offset={50} duration={500} >
       <ListItem button onClick={handleDrawerToggle}> 
        <ListItemIcon> <HomeIcon/> </ListItemIcon>
        <ListItemText primary="Home" />
        </ListItem>
        </Link>


      {/* <List> */}
        {/* {['Home', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text} >
            <ListItemIcon>{index % 2 === 0 ? <HomeIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}

        
        
        <Link activeClass="active" to="test2" spy={true} smooth={true} offset={50} duration={500} >
        <ListItem button  onClick={handleDrawerToggle}> 
        <ListItemIcon> <HomeIcon/> </ListItemIcon>
        <ListItemText primary="Home" />
        </ListItem>
        </Link>

        <ListItem button onClick={handleDrawerToggle}>
        
            <MenuIcon />
         
        
        </ListItem>

      </List> 
      
      <Divider />

      
    </div>
  );

 
  
  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ background: 'transparent', boxShadow: 'none'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
      
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            
            classes={{
              paper: classes.drawerPaper,
              
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
          
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      
  

      <div>
        <Home />
            
      </div>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};


