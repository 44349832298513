import React,{Component} from 'react';
import './About.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import DateRange from '@material-ui/icons/DateRange';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import './Home.css';
import Particles from 'react-particles-js';

const particlesOptions2 = {
    "particles": {
        "number": {
            "value": 250,
            "density": {
                "enable": false
            }
        },
        "size": {
            "value": 3,
            "random": true,
            "anim": {
                "speed": 4,
                "size_min": 0.3
            }
        },
        "line_linked": {
            "enable": false
        },
        "move": {
            "random": true,
            "speed": 1,
            "direction": "top",
            "out_mode": "out"
        }
    },
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            },
            "onclick": {
                "enable": true,
                "mode": "repulse"
            }
        },
        "modes": {
            "bubble": {
                "distance": 250,
                "duration": 2,
                "size": 0,
                "opacity": 0
            },
            "repulse": {
                "distance": 400,
                "duration": 4
            }
        }
    }
    };
  
class About extends React.Component {
  render() {
    

    return( 
        
      <div name ="About Me" className="size" id="experience" >
     <Particles className="particles2" params={particlesOptions2} />
      <div className="flexCenter">
      
      <div className="title">
          <h2  style={{fontSize:"36px",textTransform:"uppercase", marginTop:"65px",color:"#f4a950"}}>About Me </h2>
      </div>

      <div className="flexCenter" style={{margin:'15px'}}>
          {/* <h2 className="textBlue">Education</h2> */}
          
                <div className="flexCenter" style={{margin:'15px'}}>
                <h2 className="textBlue" style={{margin:'10px',color:"#f4a950"}} >Education</h2>
                <Card data-aos='flip-left' data-aos-duration="500" className="cardSize" style={{backgroundColor:"#d4d4dc"}} >
                    <CardContent>
                   
                    <div >
                   
                        <div className="flexStart">
                       <i className="fa fa-building-o fa-2x" ></i>
                       <p className ="source" style={{margin:"10px", right:"500px"}}>The City College Of New York</p>
                        </div>
                    
                    </div>
                    
                    <div className="flexStart" >
                       <i className="fa fa-calendar fa-2x" ></i>
                       <p style={{margin:"10px"}} className ="source">Aug 2015 - May 2019</p>
                        </div>
                        
                        <h2 style={{color:"#2196F3"}}>
                         Bachelor of Computer Science
                        </h2>

                           
                            {/* <Typography variant="body2" component="p">
                            well meaning and kindly.
                            <br />
                            {'"a benevolent smile"'}
                            </Typography> */}
                            
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions> */}
                        </Card>

            <div >
                <div className="flexCenter" style={{margin:'15px'}}>
                <h2 className="textBlue" style={{margin:'10px',color:"#f4a950"}}>Experience</h2>
                <Card   data-aos='flip-left' data-aos-duration="500" className="cardSize" style={{backgroundColor:"#d4d4dc"}}>
                    <CardContent>
                    <div>
                        <div className="flexStart">
                       <i className="fa fa-building-o fa-2x" ></i>
                       <p style={{margin:"10px"}}  className ="source">InterSoft Associates</p>
                        </div>
                    </div>
                    
                    <div className="flexStart">
                       <i className="fa fa-calendar fa-2x" ></i>
                       <p style={{margin:"10px"}}  className ="source">Feb 2018 - Aug 2018</p>
                        </div>
                        
                        <h2 style={{color:"#2196F3"}}>
                         Software Developer Intern
                        </h2>

                            <p  style={{margin:"10px",fontFamily:"Roboto"}}>Developed add-on features in .NET to an automated Legal Letter software application that 
                            improved efficiency in workers. Optimized loading time by 9% in legacy systems and in web applications with ASP.NET and JavaScript.</p>
                            {/* <Typography variant="body2" component="p">
                            well meaning and kindly.
                            <br />
                            {'"a benevolent smile"'}
                            </Typography> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions> */}
                        </Card>
                </div>
                <div className="flexCenter" style={{margin:'15px'}}>
                <h2 className="textBlue" style={{margin:'10px',color:"#f4a950"}}>Skills</h2>
                <div className="flexRow">
                <Card data-aos='flip-left' data-aos-duration="500" className="cardSize" style={{backgroundColor:"#d4d4dc"}}>
                    <CardContent>
                <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="C#"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="Java"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="C++"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="Python"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined"label="SQL"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="ReactJS"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="Firebase"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="HTML5"></Chip>
                    <Chip  style={{backgroundColor:'#f4a950',padding:'5px 5px',margin:'5px 5px'}}  variant="outlined" label="CSS3"></Chip>   
                    </CardContent>
                </Card>
                </div>
                </div>  
            </div>

          </div>
          
      </div>

     </div>

        <div> <br/><br/><br/></div>
      </div>
      
    )}
}
export default About;