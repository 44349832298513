import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Nav from './Nav';
import Home from './Home';
import * as serviceWorker from './serviceWorker';
import 'font-awesome/css/font-awesome.min.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import firebase from 'firebase';


AOS.init();


var config = {
    apiKey: "AIzaSyD4HUICv0_TjO5jCMunr2i9Gq7NAcSpU2E",
    authDomain: "melvintham-1721c.firebaseapp.com",
    databaseURL: "https://melvintham-1721c.firebaseio.com",
    projectId: "melvintham-1721c",
    storageBucket: "melvintham-1721c.appspot.com",
    messagingSenderId: "348963629098"
  };
  firebase.initializeApp(config);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
