import React,{Component} from 'react';
import './Home.css';
import Typography from '@material-ui/core/Typography';
import Particles from 'react-particles-js';
import './Contact.css';
import './Home.css';

class Contact extends React.Component {
    render() {
      return( 
        <div name ="Contact Me" id ="contact"  style={{backgroundColor:'#102A49'}}>
        
        <div id="Contact" className='contact' >
      <div className="flexCenter" style={{height:'50x',backgroundColor:'#102A49',paddingTop:"10px"}}>
      <h2 className ="flexCenter" style={{fontSize:"36px",textTransform:"uppercase", marginTop:"25px",color:"#f3ebdd"}}>Contact Me </h2>
      </div>
      <div className="flexRow" style={{backgroundColor:'#102A49'}}>
       <a href = "https://www.linkedin.com/in/melvin-tham/"  target="_blank"><i className = "fa fa-linkedin fa-3x" style = {{color: "#f3ebdd",padding:'3px 3px',margin:'3px 3px'}}></i></a>
       <a href = "https://github.com/MelvinTham"  target="_blank"><i className = "fa fa-github fa-3x " style = {{color: "#f3ebdd",padding:'3px 3px',margin:'3px 3px'}}></i></a>
       <a href = "mailto:mtham000@gmail.com"  target="_blank"><i className = "fa fa-envelope fa-3x" style = {{color: "#f3ebdd",padding:'3px 3px',margin:'3px 3px'}}></i></a>
       <a href = "https://www.instagram.com/tham_melvin/" target="_blank"><i className = "fa fa-instagram fa-3x" style = {{color: "#f3ebdd", padding:'3px 3px',margin:'3px 3px'}}></i></a>
       
        </div>
      
    </div>
           
          
        
       
        
        
        </div>
        
      )}
  }
  export default Contact;