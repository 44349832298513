import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import './Navbar.css';
import Home from './Home';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Mel from './pics/mel.JPG';
import './Home.css';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  rotate:{
    transform: `rotate(${270}deg)` 
  },
  bigAvatar: {
    margin: 10,
    width: 150,
    height: 150,
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    
    <div className={classes.root} >
        
      <CssBaseline />
      {/* <ClickAwayListener onClickAway={handleDrawerClose}> */}
      <AppBar
        style={{ background: 'transparent', boxShadow: 'none'}}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon style={{height:'60px',width:"60px"}} />
          </IconButton>
          
        </Toolbar>
        
      </AppBar>
      {/* </ClickAwayListener> */}

      
      <Drawer
    
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >

      
        <div >

        <Grid container justify="center" alignItems="center" className={classes.rotate}>
            <Avatar src= {Mel} data-aos="fade-in" className ={classes.bigAvatar}/>
        
        </Grid>
        <h1 className="oxygen">Melvin Tham</h1>
        </div>

        <Divider />
    
        <List>
          {['Home', 'About Me'].map((text, index) => (
              <Link activeClass="active" to={text} spy={true} smooth={true}  duration={500} >
            <ListItem button key={text} >
              <ListItemIcon style = {{color: "black"}}>
              {index % 2 === 0 ? 
              <i className="material-icons md-48">home</i>:
              <i className="material-icons md-48">face</i>
             

              }
            </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            </Link>
          ))}

          <Link activeClass="active" to="Projects" offset={10} spy={true} smooth={true}  duration={500} >
            <ListItem button key="Projects" >
              <ListItemIcon style = {{color: "black"}}>
              <i className="material-icons md-48">work</i>
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </Link>

          <Link activeClass="active" to="Contact Me" spy={true} smooth={true}  duration={500} >
            <ListItem button key="Contact" >
              <ListItemIcon style = {{color: "black"}}>
              <i class="material-icons md-48">perm_phone_msg</i>
              </ListItemIcon>
              <ListItemText primary="Contact Me" />
            </ListItem>
          </Link>
        </List>
        
        <ListItem> 
        <div className="flexRow" >
            <a href = "https://www.linkedin.com/in/melvin-tham/"  target="_blank"><i className = "fa fa-linkedin fa-2x" style = {{color: "black",padding:'3px 3px',margin:'5px 5px'}}></i></a>
            <a href = "https://github.com/MelvinTham"  target="_blank"><i className = "fa fa-github fa-2x " style = {{color: "black",padding:'3px 3px',margin:'5px 5px'}}></i></a>
            <a href = "mailto:mtham000@gmail.com"  target="_blank"><i className = "fa fa-envelope fa-2x" style = {{color: "black",padding:'3px 3px',margin:'5px 5px'}}></i></a>
            <a href = "https://www.instagram.com/tham_melvin/" target="_blank"><i className = "fa fa-instagram fa-2x" style = {{color: "black", padding:'3px 3px',margin:'5px 5px'}}></i></a>
            
        </div> 
        </ListItem>

        <Divider />
        
        <div className="flexCenter">
        <IconButton onClick={handleDrawerClose} style = {{color: "black"}} >
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      </Drawer>
     

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
        
        <Home/>
        <About/>
        <Projects/>
        <Contact/>
      </main>
     
    </div>
   
  );
}