import React,{Component} from 'react';
import './Home.css';
import Typography from '@material-ui/core/Typography';
import Particles from 'react-particles-js';

const particlesOptions = {
  "particles": {
    "number": {
        "value": 100
    },
    "size": {
        "value": 4
    }
},
"interactivity": {
    "events": {
        "onhover": {
            "enable": true,
            "mode": "repulse"
        }
    }
}
};

class Home extends React.Component {
  render() {
    return( 
      <div name ="Home">
      
        <header id ="Landing" data-aos="fade-in" >
        <Particles className="particles" params={particlesOptions} />
        <div className="flexCenter" style={{marginBottom:"100px"}} data-aos="fade-in">
          <h1 data-aos='fade-up'  > Melvin Tham</h1>
          <p style={{color:'white'}} data-aos="fade-left">Software Engineer</p>
          
        </div>
        </header>
        
      
     
      
      
      </div>
      
    )}
}
export default Home;