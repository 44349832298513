import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './Projects.css'
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import pizza from './pics/Pizza.png';
import fft from './pics/FFT.png';
import sb from './pics/sb.PNG';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './Home.css';
import Particles from 'react-particles-js';

const particlesOptions2 = {
  "particles": {
      "number": {
          "value": 150,
          "density": {
              "enable": true,
              "value_area": 1500
          }
      },
      "line_linked": {
          "enable": true,
          "opacity": 0.02
      },
      "move": {
          "direction": "right",
          "speed": 0.15
      },
      "size": {
          "value": 1
      },
      "opacity": {
          "anim": {
              "enable": true,
              "speed": 1,
              "opacity_min": 0.05
          }
      }
  },
  "interactivity": {
      "events": {
          "onclick": {
              "enable": true,
              "mode": "push"
          }
      },
      "modes": {
          "push": {
              "particles_nb": 1
          }
      }
  },
  "retina_detect": true
}
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      height:"110vh",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      
      
    },
    
   
  }));
  
  export default function AutoGrid() {
    const classes = useStyles();
  
    return (
      <div   name ="Projects" id="projects" style={{backgroundColor:"#04202C"}} >
       <Particles className="particles2" params={particlesOptions2} />
        <h2 className ="flexCenter" style={{fontSize:"36px",textTransform:"uppercase", paddingTop:"65px",color:"#C9D1C8"}}>Projects </h2>
        <div className="flexCenter" > 
          <Grid container  spacing={1} className="flexRow" style={{margin:"10px"}}>
            <Grid item xs={12} sm={6}>
            <Paper data-aos='zoom-in' data-aos-duration="500" className="projects" >
            <img src ={pizza} alt = "pizza" />
            <Typography className="flexRow " variant=" display4" style={{fontSize:"35px", color:'black',fontFamily:'Arial'}}>Weirdoughs </Typography>
                        <div className = "overlay flexCenter">
                          
                             <p className = "flexCenter ">
                             <br/>
                                This purpose of this project was to create a web-based pizza ordering system. 
                                It makes use of Google Map's API to let users search for a pizzeria and add menu items to their cart. 
                                Uses a rating system like Yelp to determine status. 
                                Weirdoughs was built with ReactJS and Firebase.                      
                                <br/>
                                <Button variant="contained" color="primary" style={{padding:'5px 5px',margin:'5px 5px',}} href = "https://github.com/MelvinTham/PizzaOrderSystem"  target="_blank">GitHub</Button>
                            {/* <div className="flexRow">
                            <Button variant="contained" color="primary" style={{padding:'5px 5px',margin:'5px 5px',}} href = "https://github.com/ayushyamitabh/PizzaOrderSystem"  target="_blank">GitHub</Button>
                            
                            </div> */}
                         </p>
                         
                    </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Paper data-aos='zoom-in' data-aos-duration="500" className="projects" style={{margin:"10px"}}>
            <img src ={fft} alt = "fft"/>
            <Typography className="flexRow" variant=" display4" style={{fontSize:"35px", color:'black'}}>Food For Thought </Typography>
                        
                        <div className = "overlay flexCenter">
                             <p className = "flexCenter ">
                             <br/>
                                FoodForThought is a website that allows users to find food recipes based off their limited ingredients in their refrigerators.
                                FoodForThought was built with Spoonacular recipe-food-nutrition API, ReactJS, and Firebase.
                                <br/>
                                <Button variant="contained" color="primary" style={{padding:'5px 5px',margin:'5px 5px'}} href = "https://github.com/MelvinTham/team-b"  target="_blank">GitHub</Button>
                            {/* <div className="flexRow">
                            <Button variant="contained" color="primary" style={{padding:'5px 5px',margin:'5px 5px'}} href = "https://github.com/MelvinTham/team-b"  target="_blank">GitHub</Button>
                            </div> */}
                         </p>
                    </div>
            </Paper>
            </Grid>

             <Grid item xs={12} sm={6}>
            <Paper  data-aos='zoom-in' data-aos-duration="500" className="projects" style={{margin:"10px"}}>
              <img src ={sb} alt = "sb"/>
              <Typography className="flexRow " variant=" display4" style={{fontSize:"35px", color:'black'}}> StudyBuddy's </Typography>
                        <div className = "overlay flexCenter">
                             <p className = "flexCenter " >
                             <br/>
                              StudyBuddy's aimed to connect students to find study buddies  to meet
                              up and study. Used Agile Development with continuous integration/continuous deployment using Travis CI. 
                              Implemented with ReactJS, NodeJS, and Firebase.
                              <br/>
                              <Button variant="contained" color="primary"  href = "https://github.com/MelvinTham/studybuddys"  target="_blank">GitHub</Button>
                              {/* <div className="flexRow">
                            <Button variant="contained" color="primary"  href = "https://github.com/MelvinTham/studybuddys"  target="_blank">GitHub</Button>
                              </div> */}
                            
                         </p>
                    </div>
            </Paper>
          </Grid>
          </Grid>
          </div>
        
       <div> <br/><br/><br/><br/></div>
      </div>
    );
  }